
export default {
  inject: ['blueprint'],
  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  methods: {
    // Files won't download directly when they are from another domain (security).
    // This function fetches it first and creates temporary local file which can then be downloaded direct.
    async downloadFile(file, name) {
      const response = await fetch(file)
      // Content to blob address
      const blob = await response.blob()
      // Create hidden downloadable links
      const objectUrl = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = objectUrl
      a.download = name
      document.body.appendChild(a)

      a.click()

      // remove
      setTimeout(() => document.body.removeChild(a), 1000)
    },

    checkIfFile(file) {
      return file.file && !file.open_in_tab
    },
  },
}
